<template>
  <v-row justify="center">
    <v-dialog v-model="userIsCanceled" persistent max-width="600">
      <v-card>
        <v-card-title primary-title class="title font-weight-medium">
          Conta cancelada
        </v-card-title>
        <v-card-text class="subheading">
          Sua Conta foi cancelada. Para voltar a utilizar os nossos serviços,
          entre em contato com a nossa equipe pelo chat, e-mail
          atendimento@simplificador.com.br ou pelo telefone (11) 4130-7932.
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { authComputed } from '@state/helpers'

export default {
  computed: {
    ...authComputed,
  },
}
</script>

<style lang="scss" scoped></style>
